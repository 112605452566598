.container {
    background-color: rgba(1,1,1, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: none;
}
.content {
    background-color: lavender;
    width: 50%;
    height: 55%;
    border-radius: 10px;
    padding: 10px;
}
.active {
    display: flex;
    justify-content: center;
    align-items: center;
}