.back {
    background: #61dafb;
}
.store {
    background: lavender;
    font-size: 22px;
}
li {
    display: inline;
    cursor: pointer;
}
li:hover {
    background: teal;
}
