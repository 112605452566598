.catalogTable {
    color: red;
    border: 0;
    border-collapse: collapse;
    padding: 0;
    border-spacing: 0;
}
tr:nth-child(odd) {
    background-color: aliceblue ;
}
tr:hover {
    background-color: #F89B4D;
}
button:hover {
    background-color: green;
}
